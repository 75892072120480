import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      // user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      // user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      const refreshToken = storageAvailable ? localStorage.getItem('refreshToken') : '';

      if (accessToken && isValidToken(accessToken)) {
        const response = await axios.get('/lms_user/api/user-profile', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const res = response.data;
        const profile = JSON.stringify(res);
        setSession(accessToken, profile, refreshToken);

        // const response = await axios.get('/api/account/my-account');

        // const { user } = response.data;

        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            // user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            // user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          // user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    try {
      const data = new URLSearchParams();
      data.append('email', email);
      data.append('password', password);
      const response = await axios({
        method: 'post',
        url: '/auth/login',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data,
      });
      const { access_token, refresh_token } = await response.data;

      const userProfile = await axios.get('/lms_user/api/user-profile', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      const res = userProfile.data;
      const profile = JSON.stringify(res);
      setSession(access_token, profile, refresh_token);

      dispatch({
        type: 'LOGIN',
      });
    } catch (error) {
      throw new Error(error.message);
    }
  }, []);

  // MANAGE ACCOUNT
  const manageAccount = async (access_token, refresh_token) => {
    try {
      const userProfile = await axios.get('/lms_user/api/user-profile', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      const res = userProfile.data;
      const profile = JSON.stringify(res);
      setSession(access_token, profile, refresh_token);

      dispatch({
        type: 'LOGIN',
      });
    } catch (error) {
      console.log(error);
    }
  };

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const response = await axios.post('/auth/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    const accessToken = localStorage.getItem('accessToken');
    await axios({
      method: 'post',
      url: `/auth/logout`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    setSession(null, null, null);
    dispatch({ type: 'LOGOUT' });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      register,
      logout,
      manageAccount,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, login, logout, register]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
