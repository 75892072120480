// utils
import { NODE_API_KEY } from '../../../config-global';
import axios from '../../../utils/axios';

const getRates = async (type, typeId) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${NODE_API_KEY}/wallet/whatsapp/rates/get`,
      params: { type, typeId },
    });
    const res = await response.data;
    return res;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
};

export { getRates };
