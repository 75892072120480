import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  config: {},
  initialized: false,
};

const whitelabelConfigSlice = createSlice({
  name: 'whitelabelConfig',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Whitelabel Config SUCCESS
    getWhitelabelConfigSuccess(state, action) {
      const config = action.payload;
      state.isLoading = false;
      state.initialized = true;
      state.config = config;
    },
  },
});

// Actions
export const { getWhitelabelConfigSuccess } = whitelabelConfigSlice.actions;

// Reducer
export default whitelabelConfigSlice.reducer;

export const getConfig = (state) => state.whitelabelConfig;

export function getWhitelabelConfig() {
  return async (dispatch) => {
    dispatch(whitelabelConfigSlice.actions.startLoading());
    try {
      const response = await axios.get('/lms_user/api/whitelable_config');
      dispatch(whitelabelConfigSlice.actions.getWhitelabelConfigSuccess(response.data.config));
    } catch (error) {
      dispatch(whitelabelConfigSlice.actions.hasError(error));
    }
  };
}

export function updateWhitelabelConfig(data) {
  return async (dispatch) => {
    dispatch(whitelabelConfigSlice.actions.startLoading());
    try {
      const formData = new FormData();

      if (typeof data?.landing_logo === 'object') {
        formData.append('landing_logo', data?.landing_logo);
      }

      if (typeof data?.favicon_logo === 'object') {
        formData.append('favicon_logo', data?.favicon_logo);
      }

      formData.append('partner_portal_url', data?.partner_portal_url);

      formData.append('customer_portal_url', data?.customer_portal_url);

      formData.append('display_name', data?.display_name);

      formData.append('meta_title', data?.meta_title);

      formData.append('meta_discription', data?.meta_discription);

      formData.append('meta_keywords', data?.meta_keywords);

      formData.append('primary_color', data?.primary_color);

      formData.append('secondry_color', data?.secondry_color);

      formData.append('crm_webhook_url', data?.crm_webhook_url);

      const response = await axios({
        method: 'post',
        url: `/lms_user/api/whitelable_config`,
        data: formData,
      });

      localStorage.setItem('primaryColor', data?.primary_color);
      localStorage.setItem('secondaryColor', data?.secondry_color);

      dispatch(whitelabelConfigSlice.actions.getWhitelabelConfigSuccess(response.data.config));
    } catch (error) {
      console.log(error);
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}
