// i18n
import './locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// redux
import { Provider as ReduxProvider } from 'react-redux';
import { useEffect } from 'react';
import { store } from './store';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import SnackbarProvider from './components/snackbar';
import { ThemeSettings, SettingsProvider } from './components/settings';
import { MotionLazyContainer } from './components/animate';
import ScrollToTop from './components/scroll-to-top';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Check our docs
// https://docs.minimals.cc/authentication/js-version

import { AuthProvider } from './auth/JwtContext';

// ----------------------------------------------------------------------

const changeFavicon = (url) => {
  const link = document.createElement('link');
  link.id = 'dynamic-favicon';
  link.rel = 'icon';
  link.href = url;

  const oldLink = document.getElementById('dynamic-favicon');
  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
};

const setDocumentTitle = (title) => {
  document.title = title;
};

const setMetaDescription = (description) => {
  let metaTag = document.querySelector("meta[name='description']");
  if (!metaTag) {
    metaTag = document.createElement('meta');
    metaTag.name = 'description';
    document.head.appendChild(metaTag);
  }
  metaTag.content = description;
};

const setMetaKeywords = (keywords) => {
  let metaTag = document.querySelector("meta[name='keywords']");
  if (!metaTag) {
    metaTag = document.createElement('meta');
    metaTag.name = 'keywords';
    document.head.appendChild(metaTag);
  }
  metaTag.content = keywords;
};

const validateLocalStorageItem = (item) => item && item !== 'null';

export default function App() {
  useEffect(() => {
    try {
      const faviconLogo = localStorage.getItem('faviconLogo');
      const metaTitle = localStorage.getItem('metaTitle');
      const metaDescription = localStorage.getItem('metaDiscription');
      const metaKeywords = localStorage.getItem('metaKeywords');

      const title = validateLocalStorageItem(metaTitle) && metaTitle;
      setDocumentTitle(title);

      const favicon = validateLocalStorageItem(faviconLogo) && faviconLogo;
      changeFavicon(favicon);

      if (validateLocalStorageItem(metaDescription)) {
        setMetaDescription(metaDescription);
      }

      if (validateLocalStorageItem(metaKeywords)) {
        setMetaKeywords(metaKeywords);
      }
    } catch (error) {
      console.error('Error setting document metadata:', error);
    }
  }, []);

  return (
    <AuthProvider>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <SettingsProvider>
            <BrowserRouter>
              <ScrollToTop />
              <MotionLazyContainer>
                <ThemeProvider>
                  <ThemeSettings>
                    <ThemeLocalization>
                      <SnackbarProvider>
                        <Router />
                      </SnackbarProvider>
                    </ThemeLocalization>
                  </ThemeSettings>
                </ThemeProvider>
              </MotionLazyContainer>
            </BrowserRouter>
          </SettingsProvider>
        </ReduxProvider>
      </HelmetProvider>
    </AuthProvider>
  );
}
