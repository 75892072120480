import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  setup: {},
  initialized: false,
};

const accountSetupSlice = createSlice({
  name: 'accountSetup',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ACCOUNT SETUP SUCCESS
    getAccountSetupSuccess(state, action) {
      const setup = action.payload;
      state.isLoading = false;
      state.initialized = true;
      state.setup = setup;
    },
  },
});

// Actions
export const { getAccountSetupSuccess } = accountSetupSlice.actions;

// Reducer
export default accountSetupSlice.reducer;

export const getSetup = (state) => state.accountSetup;

export function getAccSetup() {
  return async (dispatch) => {
    dispatch(accountSetupSlice.actions.startLoading());
    try {
      const response = await axios.get('/lms_user/api/reseller_setup');
      dispatch(accountSetupSlice.actions.getAccountSetupSuccess(response.data.setup));
    } catch (error) {
      dispatch(accountSetupSlice.actions.hasError(error));
    }
  };
}

export function updateAccSetup(data) {
  return async (dispatch) => {
    dispatch(accountSetupSlice.actions.startLoading());
    try {
      const formData = new FormData();
      formData.append('tagline', data?.tagline);
      if (typeof data?.resellerLogo === 'object') formData.append('logo', data?.resellerLogo);
      if (typeof data?.resellerLandingImg === 'object')
        formData.append('landing_image', data?.resellerLandingImg);
      formData.append('reseller_url', data?.url);
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/reseller_setup`,
        data: formData,
      });
      dispatch(accountSetupSlice.actions.getAccountSetupSuccess(response.data.setup));
    } catch (error) {
      console.log(error);
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}
