import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  users: [],
  initialized: false,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS SUCCESS
    getUsersSuccess(state, action) {
      const users = action.payload;
      state.isLoading = false;
      state.initialized = true;
      state.users = users;
    },

    // UPDATE USERS STATUS
    updateUserStatus(state, action) {
      const user = action.payload;
      state.isLoading = false;
      state.initialized = true;
      const updated = [...state.partners];
      const toUpdate = updated.findIndex((userItem) => userItem.user_id === user.user_id);
      updated[toUpdate].status = user.status;
      state.users = updated;
    },
  },
});

// Actions
export const { getUsersSuccess } = usersSlice.actions;

// Reducer
export default usersSlice.reducer;

export const getAllUsers = (state) => state.users;

export function getUsers() {
  return async (dispatch) => {
    dispatch(usersSlice.actions.startLoading());
    try {
      const response = await axios.get('/lms_user/api/reseller_user');
      dispatch(usersSlice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(usersSlice.actions.hasError(error));
    }
  };
}

export function changeStatus(userId) {
  return async (dispatch) => {
    dispatch(usersSlice.actions.startLoading());
    try {
      const response = await axios.get(`/lms_user/api/reseller_user/${userId}/status`);
      dispatch(usersSlice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(usersSlice.actions.hasError(error));
    }
  };
}

export function addUser(data) {
  return async (dispatch) => {
    dispatch(usersSlice.actions.startLoading());
    try {
      const formData = new FormData();
      formData.append('name', data?.name);
      formData.append('email', data?.email);
      formData.append('mobile', data?.phoneNumber);
      formData.append('default_ac', data?.default_ac);
      if (typeof data?.image === 'object') formData.append('image', data?.image);
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/reseller_user`,
        data: formData,
      });
      dispatch(usersSlice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function updateUser(data, userId) {
  return async (dispatch) => {
    dispatch(usersSlice.actions.startLoading());
    try {
      const formData = new FormData();
      formData.append('_method', 'patch');
      formData.append('name', data?.name);
      formData.append('email', data?.email);
      formData.append('mobile', data?.phoneNumber);
      formData.append('default_ac', data?.default_ac);
      if (typeof data?.image === 'object') formData.append('image', data?.image);
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/reseller_user/${userId}`,
        data: formData,
      });
      dispatch(usersSlice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function resetPassword(data) {
  return async () => {
    try {
      await axios({
        method: 'post',
        url: `/lms_user/api/direct_password_reset`,
        params: {
          new_password: data?.newPassword,
          confirm_password: data?.confirmPassword,
          user_type: data?.userType,
          reseller_id: data?.resellerId,
          user_id: data?.userId,
        },
      });
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}
