// utils
import { NODE_API_KEY } from '../../../config-global';
import axios from '../../../utils/axios';

const setRates = async (data) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${NODE_API_KEY}/wallet/whatsapp/rates/store`,
      data,
    });
    const res = await response.data;
    return res;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
};

export { setRates };
