import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton, Typography, Breadcrumbs, Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo';
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';
//
// import Searchbar from './Searchbar';
// import LanguagePopover from './LanguagePopover';
// import ContactsPopover from './ContactsPopover';
// import NotificationsPopover from './NotificationsPopover';

import AccountPopover from './AccountPopover';
// redux
import { useDispatch, useSelector } from '../../../store';
import { manageAccount } from '../../../store/partners/partners.slice';
import { getConfig, getWhitelabelConfig } from '../../../store/whitelabel/whitelabelConfig.slice';
// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const whatsappDemoUrl = new URL(window.location.href);

  const { config: currentConfig } = useSelector(getConfig);

  const [parent, setParent] = useState([]);

  const handleclick = () => {
    window.open(`${whatsappDemoUrl.origin}/whatsapp/demo`, '_blank');
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('parent'))) {
      setParent(JSON.parse(localStorage.getItem('parent')));
    }
  }, []);

  useEffect(() => {
    dispatch(getWhitelabelConfig());
  }, [dispatch]);

  const handleManagePartner = async (resellerId, userId, index) => {
    try {
      const url = new URL(window.location.href);
      const hostUrl = url.origin;
      const parentArr = [...parent];
      const newParentArr = parentArr.slice(0, index);
      const { access_token, refresh_token } = await dispatch(manageAccount({ resellerId, userId }));
      localStorage.setItem('parent', JSON.stringify(newParentArr));
      // if (currentConfig?.partner_portal_url) {
      //   window.location.replace(
      //     `${currentConfig?.partner_portal_url}/manage-account/${access_token}/${refresh_token}`
      //   );
      // }
      window.location.replace(`${hostUrl}/manage-account/${access_token}/${refresh_token}`);
    } catch (error) {
      console.log(error);
    }
    // console.log(index);
  };

  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      {/* <Searchbar /> */}
      <Stack direction="row" spacing={1} alignItems="center">
        {parent?.length > 0 && (
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {parent?.map((p, index) => (
              <Button
                key={index}
                onClick={() => handleManagePartner(p?.reseller_id, p?.user_id, index)}
              >
                {p?.reseller_name}
              </Button>
            ))}
            <Typography variant="h5" sx={{ color: '#212B36' }}>
              {JSON.parse(localStorage.getItem('profile'))?.reseller_details?.reseller_name}
            </Typography>
          </Breadcrumbs>
        )}
        {!parent.length > 0 && (
          <Typography variant="h5" sx={{ color: '#212B36' }}>
            {JSON.parse(localStorage.getItem('profile'))?.reseller_details?.reseller_name}
          </Typography>
        )}
      </Stack>

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 6 }}
      >
        {/* <LanguagePopover /> */}
        {/* <NotificationsPopover /> */}
        {/* <ContactsPopover /> */}
        <Button variant="outlined" onClick={handleclick}>
          Start Demo
        </Button>
        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
          borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          borderBottomStyle: 'dashed',
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
